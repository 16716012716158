import { Dialog } from 'primereact/dialog';
import { Translate } from '../../../i18n/translate';
import { Button } from 'primereact/button';
import './notificationSidebar.scss';
import { useNavigate } from 'react-router';
import ContentTemplate from '../Dashboard/ContentTemplate';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import NotificationService from '../notificationService';
import { useDispatch } from 'react-redux';
import { sendToast } from '../../../store/slices/main/mainSlice';

interface NotificationSidebarProps {
  visible: boolean;
  setFilterModalToggle: (visible: boolean) => void;
  notifications?: { today: NotificationMessage[]; older: NotificationMessage[] };
}

const NotificationSidebar = ({
  visible,
  setFilterModalToggle,
  notifications,
}: NotificationSidebarProps) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const showToast = (
    severity: 'success' | 'error',
    summary: string,
    detail: string,
    params?: { [key: string]: string },
  ) => {
    dispatch(sendToast({ severity, summary, detail, params }));
  };

  const markAllAsReadMutation = useMutation({
    mutationFn: async () => {
      const response = await NotificationService.markAllAsRead();
      return response;
    },
    onSuccess: () => {
      showToast('success', 'marked_all_notifications_as_read', '');
      queryClient.invalidateQueries({
        queryKey: ['notifications_query'],
      });
      queryClient.invalidateQueries({
        queryKey: ['notifications_query_sidebar'],
      });
    },
  });

  return (
    <Dialog
      header={
        <div className='text-lg font-semibold text-neutral-1 flex justify-between items-center'>
          <Translate value={'all_notifications'} />
          <div>
            <Button
              severity='info'
              onClick={() => {
                navigate('/notification');
                setFilterModalToggle(false);
              }}
            >
              <Translate value='view_all' />
            </Button>
            <Button
              link
              className='!text-transparent bg-gradient-to-b from-primary-gradient-from to-primary-gradient-to bg-clip-text text-transparent text-small-semibold'
              onClick={() => {
                markAllAsReadMutation.mutate();
              }}
            >
              <Translate value='mark_all_as_read' />
            </Button>
          </div>
        </div>
      }
      visible={visible}
      onHide={() => setFilterModalToggle(false)}
      draggable={false}
      className='w-[40%] scale max-h-[100%] transform scale-100 m-0 notification-sidebar'
      style={{ height: '100vh', position: 'fixed', top: 0, right: 0 }}
    >
      <div className='px-6 py-4'>
        {Object.entries(notifications ?? {}).map(([key, value]) => (
          <div key={key}>
            {value.length ? (
              <div className='text-neutral-3 text-sm-semibold py-3'>
                <Translate value={key} />
              </div>
            ) : (
              ''
            )}
            {value.map((noticiation) => (
              <div
                className='flex gap-6 py-3 border-b border-b-2 border-neutral-surface-dark'
                key={noticiation.id}
              >
                <ContentTemplate
                  rowData={noticiation}
                  isSidebar
                  setFilterModalToggle={setFilterModalToggle}
                />
              </div>
            ))}
          </div>
        ))}
      </div>
    </Dialog>
  );
};

export default NotificationSidebar;
